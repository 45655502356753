import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99')
];

export const server_loads = [0,3,4];

export const dictionary = {
		"/": [~5],
		"/(web)/about": [12],
		"/authenticated/social/posts": [36],
		"/(web)/contact": [~13],
		"/(auth)/error": [6],
		"/(web)/games": [14,[2]],
		"/(web)/games/simongame": [15,[2]],
		"/(web)/games/top-scores": [16,[2]],
		"/(auth)/login": [7],
		"/(auth)/logout": [8],
		"/(web)/market": [17],
		"/(web)/privacy": [~18],
		"/(web)/shop": [~19],
		"/(auth)/signin": [9],
		"/(auth)/signout": [10],
		"/(web)/terms": [~20],
		"/(web)/testing": [~21],
		"/(web)/testing/authorize": [~22],
		"/(web)/testing/products": [~23],
		"/users/admin": [~37,[3]],
		"/users/admin/customers": [~38,[3]],
		"/users/admin/customers/acceptsuite": [~39,[3]],
		"/users/admin/customers/customerprofiles": [~40,[3]],
		"/users/admin/customers/customerprofiles/createacustomerprofilefromatransaction": [~41,[3]],
		"/users/admin/customers/customerprofiles/createcustomerpaymentprofile": [~42,[3]],
		"/users/admin/customers/customerprofiles/createcustomerprofile": [~43,[3]],
		"/users/admin/customers/customerprofiles/createcustomershippingaddress": [~44,[3]],
		"/users/admin/customers/customerprofiles/deletecustomerpaymentprofile": [~45,[3]],
		"/users/admin/customers/customerprofiles/deletecustomerprofile": [~46,[3]],
		"/users/admin/customers/customerprofiles/deletecustomershippingaddress": [~47,[3]],
		"/users/admin/customers/customerprofiles/getcustomerpaymentprofilelist": [~49,[3]],
		"/users/admin/customers/customerprofiles/getcustomerpaymentprofile": [~48,[3]],
		"/users/admin/customers/customerprofiles/getcustomerprofileids": [~51,[3]],
		"/users/admin/customers/customerprofiles/getcustomerprofile": [~50,[3]],
		"/users/admin/customers/customerprofiles/getcustomershippingaddress": [~52,[3]],
		"/users/admin/customers/customerprofiles/updatecustomerpaymentprofile": [~53,[3]],
		"/users/admin/customers/customerprofiles/updatecustomerprofile": [~54,[3]],
		"/users/admin/customers/customerprofiles/updatecustomershippingaddress": [~55,[3]],
		"/users/admin/customers/customerprofiles/validatecustomerpaymentprofile": [~56,[3]],
		"/users/admin/customers/fraudmanagement": [~57,[3]],
		"/users/admin/customers/mobileinapptransactions": [~58,[3]],
		"/users/admin/customers/paymenttransactions": [~59,[3]],
		"/users/admin/customers/paypalexpresscheckout": [~60,[3]],
		"/users/admin/customers/recurringbilling": [~61,[3]],
		"/users/admin/customers/transactionreporting": [~62,[3]],
		"/users/admin/customers/transactionreporting/accountupdaterjobdetails": [~63,[3]],
		"/users/admin/customers/transactionreporting/accountupdaterjobsummary": [~64,[3]],
		"/users/admin/customers/transactionreporting/batchstatistics": [~65,[3]],
		"/users/admin/customers/transactionreporting/customerprofiletransactionlist": [~66,[3]],
		"/users/admin/customers/transactionreporting/merchantdetails": [~67,[3]],
		"/users/admin/customers/transactionreporting/settledbatchlist": [~68,[3]],
		"/users/admin/customers/transactionreporting/transactiondetails": [~69,[3]],
		"/users/admin/customers/transactionreporting/transactionlist": [~70,[3]],
		"/users/admin/customers/transactionreporting/unsettledtransactionlist": [~71,[3]],
		"/users/admin/dashboard": [~72,[3]],
		"/users/admin/dashboard/gamers": [73,[3]],
		"/users/admin/dashboard/news-subscribers": [74,[3]],
		"/users/user/account": [~75,[4]],
		"/users/user/account/addresses": [~76,[4]],
		"/users/user/account/addresses/delete": [~77,[4]],
		"/users/user/account/addresses/edit": [~78,[4]],
		"/users/user/account/billing": [~79,[4]],
		"/users/user/account/billing/invoices": [~80,[4]],
		"/users/user/account/billing/notifications": [~81,[4]],
		"/users/user/account/billing/paymentmethods": [~82,[4]],
		"/users/user/account/billing/profile": [~83,[4]],
		"/users/user/account/billing/subscriptions": [~84,[4]],
		"/users/user/account/cart": [~85,[4]],
		"/users/user/account/company": [~86,[4]],
		"/users/user/account/favorites": [~87,[4]],
		"/users/user/account/orders": [~88,[4]],
		"/users/user/account/preferences": [~89,[4]],
		"/users/user/account/resources": [~90,[4]],
		"/users/user/account/support": [~91,[4]],
		"/users/user/dashboard": [~92,[4]],
		"/users/user/dashboard/posts": [~93,[4]],
		"/users/user/dashboard/profile": [94,[4]],
		"/users/user/dashboard/social": [~95,[4]],
		"/users/user/dashboard/social/posts/create": [96,[4]],
		"/users/user/dashboard/social/posts/drafts": [~97,[4]],
		"/users/user/dashboard/social/posts/post/[id]": [~98,[4]],
		"/users/user/intro": [~99,[4]],
		"/(auth)/verify-email": [11],
		"/(web)/websolutions": [24],
		"/(web)/websolutions/analytics": [25],
		"/(web)/websolutions/design": [26],
		"/(web)/websolutions/design/logo-creation": [27],
		"/(web)/websolutions/design/product-page": [28],
		"/(web)/websolutions/design/static-blog": [29],
		"/(web)/websolutions/design/storefront": [30],
		"/(web)/websolutions/development": [31],
		"/(web)/websolutions/hosting": [32],
		"/(web)/websolutions/hosting/info/cpanel": [33],
		"/(web)/websolutions/hosting/info/mysql": [34],
		"/(web)/websolutions/hosting/info/webmail": [35]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';